<template>
<div class="container">
<add-verification-form is-settings/>
</div>
</template>

<script>
import AddVerificationForm from "@/components/AddVerificationForm.vue";

export default {
  name: "AccountTelegram",
  components: {AddVerificationForm}
}
</script>

<style scoped>

</style>